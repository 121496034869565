import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom';
import ProjectOrderDataTable from './ProjectOrderDataTable';
import ProjectOrderWithGiftDataTable from './ProjectOrderWithGiftDataTable';

function MyActivity() {
    const [activeSection, setActiveSection] = useState('home');
  const [content, setContent] = useState('Select an option to see its content...');

  const handleToggle = (section) => {
    setActiveSection(activeSection === section ? '' : section);
  };

  useEffect(() => {
    // Simulate fetching data
    let data = '';
    switch (activeSection) {
      case 'planted':
        data = <ProjectOrderDataTable />
        break;
        case 'home':
            data = 'Content for home by Me goes here...';
            break;
      case 'gifted':
        data = <ProjectOrderWithGiftDataTable />;
        break;
      case 'wishlist':
        data = 'Content for My Wishlist goes here...';
        break;
      case 'help':
        data = 'Content for Help Center goes here...';
        break;
      default:
        data = 'Select an option to see its content...';
    }
    setContent(data);
  }, [activeSection]);
  
  return (
    <div className="container mt-5 mb-5">
    <div className="row">
      <div className="col-lg-3 col-md-4 mb-4">
        <div className={`card p-3 ${styles.sidebar}`}>
          <ul className="list-unstyled">
          <li>
              <h5
                onClick={() => handleToggle('home')}
                className={activeSection.startsWith('home') ? styles.active : ''}
                style={{ cursor: 'pointer' }}
              >
              <i className={`ti-home ${styles.activityIcon}`}></i> Home
              </h5>
            </li>
            <li>
              <h5
                onClick={() => handleToggle('orders')}
                className={activeSection.startsWith('orders') ? styles.active : styles.link}
                style={{ cursor: 'pointer' }}
              >
                <i className={`fa fa-reorder ${styles.activityIcon}`}></i> Orders
              </h5>
              {activeSection.startsWith('orders') && (
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() => handleToggle('planted')}
                      className={activeSection === 'planted' ? styles.active : styles.link}
                    >
                      <i class={`ti-plant ${styles.activityIcon}`}>-</i> Planted by Me
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => handleToggle('gifted')}
                      className={activeSection === 'gifted' ? styles.active : styles.link}
                    >
                      <i class={`ti-plant ${styles.activityIcon}`}>-</i> Gifted to Me
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <h5
                onClick={() => handleToggle('wishlist')}
                className={activeSection === 'wishlist' ? styles.active : styles.link}
                style={{ cursor: 'pointer' }}
              >
                <i className="fa fa-heart"></i> <Link to="#" style={{color:'white'}}>My Wishlist</Link>
              </h5>
            </li>
            {/* <li>
              <h5
                onClick={() => handleToggle('help')}
                className={activeSection === 'help' ? styles.active : styles.link}
                style={{ cursor: 'pointer' }}
              >
                <Link to="#" className={styles}>Help Center</Link>
              </h5>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="col-lg-9 col-md-8">
        <div className="card p-4">
          <h3>My Activity</h3>
          <p>{content}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MyActivity