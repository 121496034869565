import { notification } from 'antd';
import styles from './styles.module.css'; // Import custom CSS for styling

export default function Notification({ message, type }) {
  const openNotification = () => {
    notification[type]({
      message: message,
      placement: 'top',
    });
  };

  openNotification();

  return null;
}
