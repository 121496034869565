// PaymentSuccess.js
import React from 'react';
import { Button } from 'antd'; // Assuming you're using Ant Design
import { useNavigate } from 'react-router-dom';

const SuccessPayment = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/');
    };

    return (
        <div>
            <h1>Payment Successful!</h1>
            <p>Thank you for your purchase. Your payment was successful.</p>
            <div>
                <Button type="primary" onClick={handleBackToHome}>
                    Back to Home Page
                </Button>
            </div>
        </div>
    );
};

export default SuccessPayment;
