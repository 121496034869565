import React from 'react'
import Header from '../component/Header';
import Footer from '../component/Footer'
import CookiePolicy from '../component/cookie/CookiePolicy';


const Cookie = () => {
    return (
        <>
            <Header />
            <CookiePolicy />
            <Footer />
        </>
    )
}

export default Cookie

