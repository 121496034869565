import React from 'react'
// import { Link } from 'react-router-dom'
// import { HashLink as Link } from 'react-router-hash-link';


function HeroSection({ id }) {
    return (
        <>
            {/* <!-- start of dc-hero-area --> */}
            <section className="dc-hero-area dc-hero-area-style-1 plantTree-dc-hero-area-style-1" id={id}>
                <div className="dc-hero-area-slider plantTreePage">
                    <div className="slide">
                        <div className="container">
                            {/* <img src="assets/images/banner/treetology-banner01.jpg" alt="banner" className="slider-bg" /> */}
                            <video
                                className="video-background vdobg plantTreeVideo"
                                autoPlay
                                loop
                                muted
                                playsInline
                                style={{
                                    position: 'absolute',
                                    top: '25%',
                                    left: '50%',
                                    width: '100%',
                                    height: '50%',
                                    objectFit: 'cover',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: '-1',
                                }}
                            >
                                <source src="/assets/video/natureVideo.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="row">
                                <div className="col col-lg-12 col-md-12 slide-content">
                                    {/* <div className="slide-top-title">
                                        <div className="slide-top-title-inner"><i><img src="assets/images/icon/tea-leaf.png"
                                            alt="" /></i><span>WE CAN
                                                CREATE YOUR FOREST</span></div>
                                    </div> */}
                                    <div className="slide-title text-center">
                                        <h2>Empowering A Greener Tomorrow, Powered By Trees.</h2>
                                    </div>
{/* 
                                    <div className="btns text-center">
                                        <Link to="#" className="ch-btn-style-1">Plant A Tree</Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </section>
            {/* <!-- end of dc-hero-area slider --> */}
        </>
    )
}

export default HeroSection