// getAllProjects

import { createSlice } from "@reduxjs/toolkit";
import routes from '../utils/routes'
import Notification from "../component/notification/Notification";
// import Notification from "../component/notification/Notification";

const initialState = {
    
projectOrderWithoutGift : [],
projectOrderWithGift : [],

    
}


export const OrderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        getGiftOrderProjects: (state,action) =>{
            state.projectOrderWithGift = action.payload
        },
        getWithoutGiftOrderProjects: (state,action) =>{
            state.projectOrderWithoutGift = action.payload
        },
    }
})

export const { getGiftOrderProjects,getWithoutGiftOrderProjects } = OrderSlice.actions;

export const fetchProjectWithoutGift = (id) => async (dispatch) => {
    try {
        const response = await routes.APIS.getOrdersWithoutGiftByuserId(id);
        // console.log("response??????",response)
        if (response.status === 200) {
            dispatch(getWithoutGiftOrderProjects(response.data))
        }
        return response;
    } catch (error) {
        console.log(error);
        Notification({ message: 'An error occurred during property Fetching.', type: 'error' });
    }
}

export const fetchProjectWithGift = (id) => async (dispatch) => {
    try {
        const response = await routes.APIS.getOrdersWithGiftByuserId(id);
        console.log("responsewithid",response)
        if (response.status === 200) {
            dispatch(getGiftOrderProjects(response.response))
        }
        return response;
    } catch (error) {
        console.log(error);
        Notification({ message: 'An error occurred during property Fetching.', type: 'error' });
    }
}


export default OrderSlice.reducer;

