import React from 'react'
import MyProfile from '../component/myProfile/MyProfile'
import Header from '../component/Header'
import Footer from '../component/Footer'

function UserMyProfile() {
    return (
        <>
            <div class="page-wrap">
                {/* <div class="preloader">
                    <div class="preloader__image" style={{ backgroundImage: 'url(assets/images/treetology-logo.png)' }}></div>
                </div> */}
                <Header />

            </div>
            <MyProfile />
            <Footer id="contact" />

        </>
    )
}

export default UserMyProfile