import { createSlice } from "@reduxjs/toolkit";
import routes from '../utils/routes'
import Notification from "../component/notification/Notification";

const initialState = {
    selectedCurrency: 'USD'
}


export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setSelectedCurrency: (state, action) => {
            state.selectedCurrency = action.payload
            console.log("setSelectedCurrency*****",state.selectedCurrency)
        }
    }
})

export const { setSelectedCurrency } = currencySlice.actions;

export default currencySlice.reducer;

