import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { userSignUp } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';

// List of all countries
const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
  "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
  "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
  "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
  "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo",
  "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica",
  "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia",
  "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany",
  "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
  "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
  "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan",
  "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
  "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
  "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco",
  "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua",
  "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
  "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
  "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
  "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
  "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
  "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland",
  "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago",
  "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
  "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  "Yemen", "Zambia", "Zimbabwe"
];

function Registration() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const registerUser = useSelector((state) => state.user.userRegistration)
  // console.log('registerUser',registerUser)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: '',
    mobileNumber: '',
    password: '',
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};

    // Validate name
    if (!formData.name) formErrors.name = 'Name is required';

    // Validate email
    if (!formData.email) formErrors.email = 'Email is required';

    // Validate country
    if (!formData.country) formErrors.country = 'Country is required';

    // Validate mobile number
    if (!formData.mobileNumber) {
      formErrors.mobileNumber = 'Mobile Number is required';
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      formErrors.mobileNumber = 'Mobile Number must be 10 digits';
    }

    // Validate password
    if (!formData.password) formErrors.password = 'Password is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate(formData)) {
      return;
    }
    await dispatch(userSignUp(formData));
    setFormData({
      name: '',
      email: '',
      country: '',
      mobileNumber: '',
      password: '',
    })
    navigate('/login')
  };
  console.log('formData', formData)

  return (
    <>
      <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
        <div className="row w-100 d-flex justify-content-center">
          <div className="col-lg-8 col-md-10 col-12 d-flex justify-content-center">
            <div className="card w-100">
              <div className="row no-gutters">
                <div className="col-md-6 d-none d-md-block login-image " />
                <div className="col-md-6">
                  <div className="card-body p-4">
                    {/* <div className="text-center mb-4">
                    <img src="/assets/images/treetology-logo.png" alt="Logo" className="logo" />
                  </div> */}
                    <h5 className="text-center mb-4 title-font">Register</h5>
                    <p>Register your account with given below the details</p>
                    <form onSubmit={handleSubmit} >
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label" style={{ color: 'white' }}>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name='name'
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && <div className="text-danger">{errors.name}</div>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label" style={{ color: 'white' }}>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name='email'
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && <div className="text-danger">{errors.email}</div>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="text" className="form-label" style={{ color: 'white' }}>Country</label>
                        <select
                          className="form-control"
                          id="country"
                          name='country'
                          value={formData.country}
                          onChange={handleChange}
                        >
                          <option value="">Select your country</option>
                          {/* <option value="India">India</option>
                          <option value="USA">USA</option>
                          <option value="UK">UK</option> */}
                          {countries.map((country, index) => (
                            <option key={index} value={country}>{country}</option>
                          ))}
                        </select>
                        {errors.country && <div className="text-danger">{errors.country}</div>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label" style={{ color: 'white' }}>Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobileNumber"
                          name='mobileNumber'
                          value={formData.mobileNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label" style={{ color: 'white' }}>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name='password'
                          value={formData.password}
                          onChange={handleChange}
                        />
                        {errors.password && <div className="text-danger">{errors.password}</div>}

                      </div>
                      {/* <div className="mb-3">
                        <label htmlFor="password" className="form-label" style={{ color: 'white' }}>Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="Confirmpassword"
                          name='Confirmpassword'
                        />
                      </div> */}
                      <button
                        type="submit"
                        className="btn btn-primary w-100 loginBtnStyle"
                      >
                        Register
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Registration