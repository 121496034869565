import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GlobalLoader } from './Loader/GlobalLoader';
import { Provider } from 'react-redux';
import store from './store/Store'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <GlobalLoader>
        <App />
      </GlobalLoader>
    </BrowserRouter>
  </Provider>

  // </React.StrictMode>
);

reportWebVitals();
