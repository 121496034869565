import React from 'react'
// import LoginView from '../component/login/LoginView';
import Header from '../component/Header';
import Footer from '../component/Footer'
import RefundPolycy from '../component/refund/RefundPolycy';


const Refund = () => {
    return (
        <>
            <Header />
            <RefundPolycy />
            <Footer />
        </>
    )
}

export default Refund

