import React, { useRef } from 'react';
import TestimonialCard from './TestimonialCard';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

function Testimonial({ id }) {
  const carouselRef = useRef(null);

  const testimonials = [
    {
      imgSrc: 'assets/images/testimonial/Prince1.png',
      name: 'Prince Bharali',
      position: 'Environmental Scientist',
      rating: 5,
      text: 'Adopting a tree through Treetology was one of the best decisions we have made for our environment. Not only does it help offset our carbon footprint, but it also contributes to local green spaces and supports community projects. The tracking system is fantastic, letting us follow the growth of our tree and feel connected to the cause.'
    },
    {
      imgSrc: 'assets/images/testimonial/DharmeshShah.jpg',
      name: 'Dharmesh Shah',
      position: 'President of Sales',
      rating: 5,
      text: 'Planting a tree with Treetology was a meaningful way to celebrate my daughter’s birthday. The process was simple, and I love that we can track our tree’s progress. It feels great knowing that our contribution is helping combat climate change and enhance biodiversity. Highly recommend Treetology for anyone looking to make a positive impact.'
    },
    {
      imgSrc: 'assets/images/testimonial/YashrajJain1.jpg',
      name: 'Yashraj',
      position: 'Corporate Social Responsibility Manager',
      rating: 5,
      text: 'Treetology’s innovative approach to tree planting and adoption is truly impressive. By adopting a tree, I’ve been able to contribute to sustainable development and improve air quality in my city. The certificate of ownership and growth updates make the experience even more rewarding. Treetology is making a real difference, and I’m proud to be part of it.'
    },
    {
      imgSrc: 'assets/images/testimonial/diya-sinha.jpg',
      name: 'Divya Sinha',
      position: 'Community Engagement Coordinator',
      rating: 5,
      text: 'As a business owner, partnering with Treetology to plant trees was a fantastic way to enhance our corporate social responsibility efforts. Not only are we offsetting our carbon emissions, but we are also contributing to important environmental and community initiatives. The ease of adoption and the detailed growth tracking make it a worthwhile investment.'
    }
  ];

  const carouselSettings = {
    className: 'testimonial-items testimonial-active testimonialCardMargin',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <>
      <section className="testimonial-section pb-0" id={id}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="ch-top-title-s2">
                <span>Our Testimonials</span>
                <h2 className="site-split-text ch-split-in-up">What our Customers Say </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center position-relative">
            <button className={`${styles.carouselButton} ${styles.leftButton}`} onClick={() => carouselRef.current.prev()}>
             <div>
             <LeftOutlined  />
             </div>
            </button>
            <Carousel {...carouselSettings} autoplay ref={carouselRef}>
              {testimonials.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  imgSrc={testimonial.imgSrc}
                  name={testimonial.name}
                  position={testimonial.position}
                  rating={testimonial.rating}
                  text={testimonial.text}
                />
              ))}
            </Carousel>
            <button className={`${styles.carouselButton} ${styles.rightButton}`} onClick={() => carouselRef.current.next()}>
            <div>
            <RightOutlined />
            </div>
            </button>
          </div>
        </div>
        <div className="shape-1"><img src="assets/images/testimonial/shape2.png" alt="" /></div>
        <div className="shape-2"><img src="assets/images/testimonial/shape3.png" alt="" /></div>
      </section>
    </>
  );
}

export default Testimonial;
