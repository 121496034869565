import { createSlice } from "@reduxjs/toolkit";
import routes from '../utils/routes'
import Notification from "../component/notification/Notification";

const initialState = {
    // id: null,
    // name: null,
    // email: null,
    // token: null,
    // role: null,
    userRegistration: [],
    userInfo: {},
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveReducerInfo: (state, action) => {

            state.userInfo = action.payload
        },
        registeredUser: (state, action) => {
            state.userRegistration = action.payload
        },
        logout: (state) => {
            state.userInfo = null;
        },
        // getUserInfo: (state,action) =>{
        //     state.userInfo = action.payload
        // }
    }
})

export const { saveReducerInfo, registeredUser, logout } = userSlice.actions;

// export const fetchUserInfo = () => async (dispatch) => {
//     const response = await routes.APIS.
//     dispatch(saveReducerInfo(response.data.user));
//     return response;
// }

export const userSignUp = (data) => async (dispatch) => {
    try {
        const resp = await routes.APIS.registeredUser(data);
        // console.log("resp>>>>", resp)

        if (resp.status === 200) {
            Notification({ message: resp.message, type: 'success' });
            dispatch(registeredUser(resp))
        } else {
            Notification({ message: resp.message, type: 'error' });
        }
        return resp;
    } catch (error) {
        Notification({ message: 'An error occurred during account creation.', type: 'error' });
    }
}

export const userLogin = (data) => async (dispatch) => {
    try {
        const response = await routes.APIS.userlogin(data);
        // console.log("response", response);
        // if (response.status === 200) {
        //     Notification({ message: response.message, type: 'success' });
        //     await Localstorage.JWT_TOKEN.set(response.token);
        //     dispatch(saveAdminInfo(response.data));
        //     // router.push('/dashboard');
        // } else {
        //     Notification({ message: response.message, type: 'error' });
        // }
        return response;
    } catch (error) {
        console.log(error);
        Notification({ message: 'An error occurred during login.', type: 'error' });

    }
}

export const fetchEndUserInfo = () => async (dispatch) => {
    const response = await routes.APIS.getUserInfo();
    // console.log("fetchAdminInfo***", response.data)
    dispatch(saveReducerInfo(response.data));
    // return response;
}

export const UpdatePasswordByID = (id, data) => async (dispatch) => {
    try {
        // dispatch(changeLoadingState(true))
        // console.log("console.log(id, data)",id, data);
        const response = await routes.APIS.updatePasswordByID(id, data);
        // console.log("updatepwd",response)
        if (response.status === 200) {
            Notification({ message: response.message, type: 'success' });
            dispatch(fetchEndUserInfo(id))
        } else {
            Notification({ message: response.message, type: 'error' });
        }
        return response;
    } catch (error) {
        console.log(error)

    }
    // finally {
    //     dispatch(changeLoadingState(false))
    // }
}

// updateProfileById

export const updateProfileById = (id, data) => async (dispatch) => {
    try {
        // dispatch(changeLoadingState(true))
        console.log("console.log(id, data)",id, data);
        const response = await routes.APIS.UpdateProfileById(id, data);
        // console.log("updatepwd",response)
        if (response.status === 200) {
            Notification({ message: response.message, type: 'success' });
            dispatch(fetchEndUserInfo(id))
        } else {
            Notification({ message: response.message, type: 'error' });
        }
        return response;
    } catch (error) {
        console.log(error)

    }
    // finally {
    //     dispatch(changeLoadingState(false))
    // }
}

export default userSlice.reducer;

