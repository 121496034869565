import React from 'react'
import Header from '../component/Header';
import Footer from '../component/Footer'
import PrivacyPolicy from '../component/privacy/PrivacyPolicy';


const Privacy = () => {
    return (
        <>
            <Header />
            <PrivacyPolicy />
            <Footer />
        </>
    )
}

export default Privacy

