import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CookiePolicy = () => {
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="mb-4">Cookie Policy</h1>

                    <section className="mb-4">
                        <p>
                            Effective Date: 23/09/2024
                        </p>
                        <p>
                            At Treetology (operated by Sustainology Climate Solutions Private Limited), we are committed to ensuring your privacy while providing the best possible user experience on our website. This Cookie Policy explains how and why cookies and similar technologies are used on our website and how you can manage your cookie preferences.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>1. What Are Cookies?</h2>
                        <p>
                            Cookies are small text files that are stored on your device (such as a computer, tablet, or smartphone) when you visit a website. Cookies help us remember your preferences, understand how our site is being used, and improve your browsing experience.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>2. Types of Cookies We Use</h2>
                        <p>
                            <strong>Essential Cookies:</strong>These cookies are crucial for the basic functioning of our website. They enable core functions like navigation, access to secure areas, and making purchases. Without these cookies, the site cannot operate properly.
                        </p>
                        <p>
                            <strong>Performance and Analytics Cookies:</strong> These cookies help us understand how visitors interact with our website by collecting anonymous information such as which pages are most visited, how users navigate through the site, and any errors encountered. This helps us improve the performance of our website.
                        </p>
                        <p>
                            <strong>Functionality Cookies:</strong>These cookies remember choices you make on the website (such as your language or region) to offer enhanced and more personalized features. For example, they help retain your preferences for future visits.
                        </p>
                        <p>
                            <strong>Advertising Cookies:</strong> We use these cookies to deliver more relevant ads based on your browsing activity on our website and other sites. These cookies track your interaction with ads and measure the effectiveness of our marketing campaigns.
                        </p>

                    </section>

                    <section className="mb-4">
                        <h2>3. Third-Party Cookies</h2>
                        <p>
                            In some cases, we allow third-party providers, such as analytics or advertising partners, to set cookies on our website. These cookies help us monitor and analyze website traffic, as well as display relevant advertisements. The use of third-party cookies is subject to their own privacy policies, and we recommend reviewing their policies to understand how they handle your information.
                        </p>
                        <p>
                            Some of the third-party tools and services that may place cookies include:
                        </p>
                        <p>
                            <strong>Google Analytics:</strong> This tool helps us understand how users interact with our website, allowing us to make data-driven improvements.
                        </p>
                        <p>
                            <strong>Advertising Networks:</strong> Third-party advertising networks may place cookies to deliver targeted ads and analyze the effectiveness of ad campaigns.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>4. How We Use Cookies</h2>
                        <p>
                            We use cookies for several purposes:
                        </p>
                        <p>
                            •   To ensure that the website functions properly.
                        </p>
                        <p>
                            •   To analyze site performance and improve usability.
                        </p>
                        <p>
                            •   To provide personalized content and advertisements.
                        </p>
                        <p>
                            •   To remember your preferences and past interactions on the website.
                        </p>
                    </section>

                    <section>
                        <h2>5. Managing Your Cookie Preferences</h2>
                        <p>
                            You have the right to manage and control your cookie preferences. You can adjust your browser settings to block or delete cookies if you prefer. However, please note that some parts of our website may not function properly without cookies.
                        </p>
                        <p>
                            Here’s how you can manage cookies on some popular browsers:
                        </p>
                        <p>
                            •   Google Chrome: https://support.google.com/chrome/answer/95647?hl=en
                        </p>
                        <p>
                            •   Mozilla Firefox: https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                        </p>
                        <p>
                            •   Safari: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                        </p>
                        <p>
                            •   Microsoft Edge: https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies
                        </p>
                        <p>
                            You can also opt out of targeted advertising through the following links:
                        </p>
                        <p>
                            •   Network Advertising Initiative: http://optout.networkadvertising.org/
                        </p>
                        <p>
                            •   Your Online Choices: http://www.youronlinechoices.com/
                        </p>
                    </section>

                    <section>
                        <h2>6. Cookie Retention</h2>
                        <p>
                            The duration of time that cookies are stored on your device can vary. Some cookies are session-based, meaning they are deleted once you close your browser, while others may remain on your device for a set period or until manually deleted.
                        </p>
                    </section>

                    {/* Add additional sections from 7 to 20 similarly */}

                    <section>
                        <h2>7. Changes to This Cookie Policy</h2>
                        <p>
                            We may update this Cookie Policy from time to time to reflect changes in technology, our practices, or relevant laws. Any updates will be posted on this page, and the 'Effective Date' at the top will be updated accordingly. We encourage you to review this policy periodically to stay informed about how we use cookies.
                        </p>

                    </section>

                    <section>
                        <h2>8. Contact Us</h2>
                        <p>
                            If you have any questions or concerns regarding our use of cookies or this policy, please contact us at:

                            Email: operations@sustainology.life

                        </p>
                    </section>
                </Col>
            </Row>
        </Container>
    );
};

export default CookiePolicy;
