import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
// import 'antd/dist/antd.css';
import styles from './styles.module.css'
import ProjectCard from './ProjectCard';


function OurProject({ id }) {

    const [activeMembers, setActiveMembers] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [successfulProjects, setSuccessfulProjects] = useState(0);
console.log(activeMembers,totalProjects,successfulProjects)
    useEffect(() => {
        const animateValue = (start, end, duration, setValue) => {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                setValue(Math.floor(progress * (end - start) + start));
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        };

        animateValue(0, 25, 3000, setActiveMembers);
        animateValue(0, 350, 3000, setTotalProjects);
        animateValue(0, 500, 3000, setSuccessfulProjects);
    }, []);


    const projects = [
        { imgSrc: 'assets/images/gallery/proj1.jpeg', location: 'Bangalore, India', title: 'Urban Plantations' },
        { imgSrc: 'assets/images/gallery/proj2.jpeg', location: 'Indonesia', title: 'Siberut Island' },
        { imgSrc: 'assets/images/gallery/proj3.jpeg', location: 'Papua New Guinea', title: 'Woodlark Island' },
        { imgSrc: 'assets/images/gallery/proj4.jpeg', location: 'Sunderbans, India', title: 'Sunderbans Restoration' },
        { imgSrc: 'assets/images/gallery/proj5.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj6.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj7.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj9.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj10.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj11.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj12.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj13.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj14.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj15.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj16.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj17.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj18.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj19.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj20.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },
        { imgSrc: 'assets/images/gallery/proj21.jpeg', location: 'Tanzania', title: 'Rufiji Delta' },



    ];

    const carouselSettings = {
        // classNameName: 'testimonial-items testimonial-active testimonialCardMargin',project-active
        classNameName: 'project-active projectCardMargin',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
            {/* <!-- start of ch-choose-area --> */}
            <section className="ch-choose-area ptb-120" id={id}>
                <div className="pattern"></div>
                <div className="container">
                    <div className="ch-choose-wrap">
                        <div className="row justify-content-center">
                            {/* <div className="col-xl-3 col-lg-4 col-md-12 col-12 order-lg-1 order-2">
                                <div className={`ch-choose-img wow fadeInUp ${styles.backgroundImage}`} data-wow-duration="1000ms">
                                    <img src="assets/images/banner/Group-238413.png" alt="" style={{ marginBottom: '-90px' }} />
                                </div>
                            </div> */}
                            <div className="col-xl-9 col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                                <div className="ch-choose-text">
                                    <div className="ch-top-title-s3 mb-80">
                                        {/* <span>Our Project</span> */}
                                        <h1 className={`${styles.Gallerytitle} pt-0`}>Photo Gallery</h1>

                                        {/* <h2 className="site-split-text ch-split-in-right">Take a look some of our
                                            top-notch recent projects that people always love.</h2> */}
                                        {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, dui velit enim at
                                            venenatis quis vivamus cum, condimentum pellentesque per ut habitasse
                                            natoque. Condimentum molestie className hac sed rhoncus</p> */}
                                    </div>
                                    {/* <div className="funfact-area">
                                        <div className="row align-items-center">
                                            <div className="col col-lg-4 col-sm-4 col-6">
                                                <div className="info wow fadeInUp" data-wow-duration="1000ms">
                                                    <h3><span className="odometer" data-count="25">{activeMembers}</span>K+</h3>
                                                    <p>Our active members</p>
                                                </div>
                                            </div>
                                            <div className="col col-lg-4 col-sm-4 col-6">
                                                <div className="info wow fadeInUp" data-wow-duration="1200ms">
                                                    <h3><span className="odometer" data-count="350">{totalProjects}</span>K+</h3>
                                                    <p>Our Total Projects</p>
                                                </div>
                                            </div>
                                            <div className="col col-lg-4 col-sm-4 col-6">
                                                <div className="info wow fadeInUp" data-wow-duration="1400ms">
                                                    <h3><span className="odometer" data-count="500">{successfulProjects}</span>+</h3>
                                                    <p>Successful projects </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end of ch-choose-area  --> */}

            {/* <!-- start of ch-project-area  --> */}
            <section className="ch-project-area ptb-120 pt-0">
                <div className="container-fluid">
                    <div className="project-wrap">
                        {/* <div className="project-active owl-carousel"> */}
                        {/* {projects.map((project, index) => (
                                <ProjectCard
                                    key={index}
                                    imgSrc={project.imgSrc}
                                    location={project.location}
                                    title={project.title}
                                />
                            ))} */}

                        <Carousel {...carouselSettings} autoplay>
                            {projects.map((project, index) => (
                                <div key={index} classNameName={styles.carouselSlide}>
                                    <ProjectCard
                                        imgSrc={project.imgSrc}
                                        location={project.location}
                                        title={project.title}
                                    />
                                </div>
                            ))}
                        </Carousel>
                        {/* </div> */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default OurProject