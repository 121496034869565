// store/blogsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import routes from '../utils/routes';

const initialState = {
    allBlogs: [],
    selectedBlog: {},
};

export const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        getAllBlogs: (state, action) => {
            state.allBlogs = action.payload;
        },
        getSelectedBlog: (state, action) => {
            state.selectedBlog = action.payload;
        }
    }
});

export const { getAllBlogs, getSelectedBlog } = blogsSlice.actions;

export const fetchwebsiteBlogs = () => async (dispatch) => {
    try {
        const response = await routes.APIS.getAllBlogs();
        dispatch(getAllBlogs(response.response.getAllBlogs)); // Adjust based on actual response format
    } catch (error) {
        console.error("Error fetching blogs:", error);
    }
};

export const fetchwebsiteBlogByLink = (link) => async (dispatch) => {
    try {
        const response = await routes.APIS.getBlogByLink(link);
        
        dispatch(getSelectedBlog(response.response));
    } catch (error) {
        console.error("Error fetching blog by link:", error);
    }
};

export default blogsSlice.reducer;
