import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import ViewCartPage from '../component/cart/ViewCartPage'

function ViewCart() {
  return (
    <>
      <div class="page-wrap">
        <Header />
        <ViewCartPage />
        <Footer />
      </div>
    </>
  )
}

export default ViewCart