import React from 'react'

function About({ id }) {
    return (
        <>
            {/* <section className={`about-area `} id={id} ref={sectionRef}>
                <div className={`container ${styles.aboutStyle}`}>
                    <div className="about-wrap">
                        <div className="row">
                            <div className="col col-lg-12 col-md-12 col-12 text-center">
                                <div className="about-center-item wow fadeInRightSlow" data-wow-duration="1400ms">
                                    <div className="about-right-text">
                                        <h2 className="site-split-text ch-split-in-center"> Why Partner With Us ?</h2>
                                        <p style={{ letterSpacing: '0.8px', padding: '0 50px' }}>Since our establishment in 2014, we've been committed to simplifying environment contribution for individuals and businesses.With your support, we drive environmental conservation and global reforestation efforts.
                                            From small businesses to fortune 500 companies,we make reforestation at the core of our operations.Our flexibility and tailored solutions allow your business to create a healthier climate, protect biodiversity and make a difference.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-lg-1 "></div>
                            <div className="col col-lg-10 col-md-12 col-12">
                                <div className="about-left-item wow fadeInLeftSlow" data-wow-duration="1400ms">
                                    <div className={styles.iconContainer}>
                                        <div className={styles.iconWrapper}>
                                            <img
                                                src='/assets/images/icon/Partnerr.png'
                                                alt='partner'
                                                className={styles.icon}
                                            />
                                            <span className={styles.count}>681 +</span>
                                            <span className={styles.name}>Planting Partners</span>
                                        </div>
                                        <div className={styles.iconWrapper}>
                                            <img
                                                src='/assets/images/icon/world.png'
                                                alt='partner'
                                                className={styles.icon}
                                            />
                                            <span className={styles.count}>80 +</span>
                                            <span className={styles.name}>Countries</span>
                                        </div>
                                        <div className={styles.iconWrapper}>
                                            <img
                                                src='/assets/images/icon/tree.png'
                                                alt='partner'
                                                className={styles.icon}
                                            />
                                            <span className={styles.count}>1 Cr +</span>
                                            <span className={styles.name}>Trees Planted</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- start of about-area --> */}
            <section className="about-area pb-0" id={id} style={{ paddingTop: "24px" }}>
                <div className="container">
                    <div className="about-wrap">
                        <div className="row">

                            <div className="col col-lg-12 col-md-12 col-12 text-center">
                                <div className="about-center-item fadeInRightSlow" data-wow-duration="1400ms">
                                    <div className="about-right-text">
                                        <h2 className="site-split-text ch-split-in-center"> Why Treetology ?</h2>
                                        <p>With Treetology, you can plant a tree, adopt a tree, or gift a tree to make a significant environmental impact. Whether you're marking a special occasion or committing to a greener planet, each tree you plant helps reduce carbon emissions, supports local ecosystems, and promotes sustainable development. Our user-friendly tracking system keeps you updated on your tree’s progress and provides a certificate of ownership. Join us in fostering a sustainable future—one tree at a time. </p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-lg-1 "></div>
                            <div className="col col-lg-10 col-md-12 col-12">
                                <div className="about-left-item fadeInLeftSlow" data-wow-duration="1400ms">
                                    <img src="/assets/images/map5.png" alt="map" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- <div className="left-shape"><img src="assets/images/treetology-logo.png" alt="" /></div> --> */}
            </section>
            {/* <!-- end of about-area  --> */}
        </>
    )
}

export default About