import React from 'react';

function TestimonialCard({ imgSrc, name, position, rating, text }) {
  return (
    <div className="testimonial-item">
      <div className="testimonial-item-inner">
        <div className="testimonial-text">
          <ul className="ratting">
            {Array(rating).fill().map((_, i) => (
              <li key={i}><i className="icon-star"></i></li>
            ))}
          </ul>
          <p>{text}</p>
          <div className="info-text-bio">
            <h4>{name}</h4>
            {/* <span>{position}</span> */}
          </div>
        </div>
        <div className="info-img">
          <img src={imgSrc} alt={name} />
          <div className="quote d-none d-sm-block"><i className="icon-quote-right-1"></i></div>
          <div className="t-shape"><img src="assets/images/testimonial/shape.png" alt="" /></div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard;
