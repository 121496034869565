import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { fetchEndUserInfo, UpdatePasswordByID, updateProfileById } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
// import Certificate from '../../certificateComp/Certificate'

function MyProfile() {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState({});
  const [oldPasswordVisible, setoldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confPasswordVisible, setConfPasswordVisible] = useState(false);

  const userInfo = useSelector((state) => state.userReducer.userInfo);
  console.log("profileuserInfo", userInfo)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const validateForm = () => {
    const newErrors = {};
    if (!formData.oldPassword.trim()) {
      newErrors.oldPassword = 'Old Password is required';
    }
    if (!formData.newPassword.trim()) {
      newErrors.newPassword = 'New Password is required';
    } else if (formData.newPassword.length < 8) {
      newErrors.newPassword = 'New Password should be at least 8 characters';
    }
    if (!formData.confirmPassword.trim()) {
      newErrors.confirmPassword = 'Confirm Password is required';
    } else if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = 'New Password and Confirm Password do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  useEffect(() => {
    if (!userInfo._id) {
      dispatch(fetchEndUserInfo());
    } else {
      setFormData({
        name: userInfo?.name,
        email: userInfo?.email,
        mobileNumber: userInfo?.mobileNumber,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    }
  }, [userInfo, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleProfileUpdate = () => {
    try {
      console.log("handleProfileUpdate******", userInfo._id, formData)
      dispatch(updateProfileById(userInfo._id, formData))
    } catch (error) {
      console.log(error)
    }
  }

  const handlePasswordChange = () => {
    // if (formData.newPassword !== formData.confirmPassword) {
    //   Notification({ message: 'Passwords do not match.', type: 'error' });
    //   return;
    // }
    if (!validateForm()) {
      return;
    }
    dispatch(UpdatePasswordByID(userInfo._id, { oldPassword: formData.oldPassword, newPassword: formData.newPassword }));
    console.log('Password change data:', {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
      confirmPassword: formData.confirmPassword
    });

    // dispatch(UpdatePasswordByID(userInfo._id, {
    //   oldPassword: formData.oldPassword,
    //   newPassword: formData.newPassword
    // }

    // ));
  };

  console.log('Password change data:', {
    oldPassword: formData.oldPassword,
    newPassword: formData.newPassword,
    confirmPassword: formData.confirmPassword
  })

  return (
    <>
      <div className={`container mt-5 mb-5 ${styles.profileContainer}`}>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className={`${styles.card} p-4`}>
              <div className="d-flex align-items-center mb-4">
                <img
                  src="/assets/images/bgImg/user-profile1.jpg"
                  alt="user"
                  className="rounded-circle"
                  width="80" height="80"
                />
                <div className="ml-3" style={{ marginLeft: '1rem' }}>
                  <h4>Hi,  {userInfo.name}</h4>
                  {/* <button
                    className="btn btn-primary btn-sm"
                  >
                    Upload Photo
                  </button> */}
                </div>

              </div>
              {/* <div className='d-flex justify-content-end'> <button className="btn btn-success" >Download Certificate</button></div> */}

              <hr />
              <div className="row mb-3">
                <div className="col-lg-4 col-md-6 mb-3">
                  <label htmlFor="name" className={styles.labelColor}>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <label htmlFor="email" className={styles.labelColor}>Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mb-3">
                  <label htmlFor="mobileNumber" className={styles.labelColor}>Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNumber"
                    className="form-control"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>

              </div>
              {/* <button className="btn btn-success">Save</button> */}
              <button className="btn btn-success" onClick={handleProfileUpdate}>Submit</button>


            </div>
          </div>

          {!userInfo.isGoogleLogin && (
            <>
              <div className="col-lg-12">
                <div className={`${styles.card} p-4`}>
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 mb-3 position-relative">
                      <label htmlFor="old-password" className={styles.labelColor}>Old Password</label>
                      <input
                        type={oldPasswordVisible ? 'text' : 'password'}
                        name="oldPassword"
                        className="form-control"
                        value={formData.oldPassword}
                        onChange={handleChange}
                      />
                      <i
                        className={`fa ${oldPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} position-absolute pwdIconprofile`}
                        onClick={() => setoldPasswordVisible(!oldPasswordVisible)}
                      />
                      {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3 position-relative">
                      <label htmlFor="new-password" className={styles.labelColor}>New Password</label>
                      <input
                        type={newPasswordVisible ? 'text' : 'password'}
                        name="newPassword"
                        className="form-control"
                        value={formData.newPassword}
                        onChange={handleChange}
                      />
                      <i
                        className={`fa ${newPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} position-absolute pwdIconprofile`}
                        onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3 position-relative">
                      <label htmlFor="confirm-password" className={styles.labelColor}>Confirm New Password</label>
                      <input
                        type={confPasswordVisible ? 'text' : 'password'}
                        name="confirmPassword"
                        className="form-control"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <i
                        className={`fa ${confPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} position-absolute pwdIconprofile`}
                        onClick={() => setConfPasswordVisible(!confPasswordVisible)}
                      />
                    </div>
                  </div>
                  <button className="btn btn-success" onClick={handlePasswordChange}>Submit</button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MyProfile