import React, { createContext, useCallback, useState } from 'react'

const GlobalLoaderContext = createContext();


function GlobalLoader({ children }) {
    const [loading, setLoading] = useState(false);
    // const [blurBackground, setBlurBackground] = useState(false); // Add this state


    // const showLoader = () => {
    //     console.log('Show loader called');
    //     setLoading(true);
    //     // setBlurBackground(true); // Show blur background
    // };

    // // Hide the loader
    // const hideLoader = () => {
    //     console.log('Hide loader called');
    //     setLoading(false);
    //     // setBlurBackground(false); // Hide blur background
    // };

    const showLoader = useCallback(() => {
        console.log('Show loader called');
        setLoading(true);
    }, []);

    const hideLoader = useCallback(() => {
        console.log('Hide loader called');
        setLoading(false);
    }, []);


    return (
        <GlobalLoaderContext.Provider value={{ loading, showLoader, hideLoader }}>
            {children}
            {loading && (<div className="preloader">
                <div className="preloader__image " >
                
                <img className="loaderimgSize" src='/assets/images/treetology-logo.png' alt='loader' />
                </div>
            </div>
            )}
             {/* {blurBackground && <div className="blur-background"></div>} */}
        </GlobalLoaderContext.Provider>
    )
}

export {GlobalLoader, GlobalLoaderContext}
 