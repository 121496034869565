import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { Helmet } from 'react-helmet';
import BlogCard from './BlogCard';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchwebsiteBlogs } from '../../store/blogsSlice';

function BlogPage({ id }) {
    const dispatch = useDispatch();
    const blogSelector = useSelector((state) => state.blogsReducer.allBlogs);
    const loading = useSelector((state) => state.blogsReducer.loading);
    const error = useSelector((state) => state.blogsReducer.error);

    useEffect(() => {
        dispatch(fetchwebsiteBlogs());
    }, [dispatch]);

    return (
        <>
            {/* <Helmet>
                <title>Blog Page</title>
            </Helmet> */}
            <div className={`container ptb-120 ${styles.cartContainer}`}>
                {loading ? (
                    <p>Loading blogs...</p>
                ) : error ? (
                    <p>Error loading blogs: {error}</p>
                ) : (
                    <Row gutter={[16, 16]} justify="center">
                    {blogSelector.map((blog) => (
                        <Col key={blog._id} xs={24} sm={12} md={8} lg={6}>
                            <BlogCard
                                imgSrc={`${process.env.REACT_APP_BACKEND_URL}/${blog.image || ''}`}
                                title={
                                    blog.title.length > 20
                                        ? `${blog.title.slice(0, 20)}...`
                                        : blog.title
                                }
                                link={blog.link}
                            />
                        </Col>
                    ))}
                </Row>
                
                )}
            </div>
        </>
    );
}

export default BlogPage;
