import React from 'react'
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';




function Pricing({ id }) {
    return (
        <>
            {/* <!-- start of pricing-section --> */}
            <div className="pricing-section ptb-120" id={id}>

                <div className="container">
                    <div className="pricing-items">
                        <div className="row">
                            <div className="col col-lg-4 col-md-12 col-12">
                                <div className="ch-top-title">
                                    <span>Plant A Tree</span>
                                    <h2 className="site-split-text ch-split-in-right">Give Life to a Tree in your name</h2>
                                    <p>We make a firm pledge to each of our patrons and the planet – the trees you help plant will not be cut down. We’re planting for the future, establishing living legacies that contribute to long-term environmental sustainability and biodiversity. </p>

                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="tab-wrap">
                                    <div id="tab1" className="price-tab-content active">
                                        <div className="row">
                                            <div className="col col-lg-6 col-md-6 col-12">
                                                <div className="pricing-item fadeInLeftSlow" data-wow-duration="1000ms">
                                                    <div className="pricing-item-inner"
                                                        style={{ background: 'url(assets/images/pricing-bg.jpg) no-repeat center top', backgroundSize: 'cover' }}>
                                                        <div className="price-wrap">
                                                            <div className="price-plan">
                                                                <span>Plant A Tree</span>
                                                                <p>Plant a tree and enjoy these benefits:</p>
                                                            </div>

                                                        </div>
                                                        <ul>
                                                            <li>Reduce Carbon Footprint</li>
                                                            <li>Boost Air Purity</li>
                                                            <li>Encourage Wildlife Habitat
                                                            </li>
                                                            <li>Cool Urban Areas
                                                            </li>
                                                            <li>Create Long-term Impact
                                                            </li>
                                                        </ul>
                                                        <Link to="/#our-projects">CHOOSE PROJECT</Link>
                                                        <div className="badge">
                                                            <span>Most Popular</span>
                                                        </div>
                                                    </div>
                                                    <div className="shape"><img src="/assets/images/price-shape.png" alt="" /></div>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-md-6 col-12">
                                                <div className="pricing-item fadeInRightSlow" data-wow-duration="1000ms">
                                                    <div className="pricing-item-inner"
                                                        style={{ background: 'url(/assets/images/pricing-bg.jpg) no-repeat center top', backgroundSize: 'cover' }}>
                                                        <div className="price-wrap">
                                                            <div className="price-plan">
                                                                <span>ADOPT TREE</span>
                                                                <p>Adopt a tree and enjoy these benefits:
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <ul>
                                                            <li>Lower Carbon Emissions
                                                            </li>
                                                            <li>Boost Urban Greenery
                                                            </li>
                                                            <li>Improve Air Quality
                                                            </li>
                                                            <li>Support Ecosystem Restoration</li>
                                                            <li>Build a Sustainable Future
                                                            </li>
                                                        </ul>
                                                        <Link to="/#our-projects">CHOOSE PROJECT</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-shape"><img src="/assets/images/pricing-shape.png" alt="" /></div>
            </div>
            {/* <!-- end of pricing-section --> */}
        </>
    )
}

export default Pricing