import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';




function HeroSection({ id }) {
    useEffect(() => {
        const slideElement = document.querySelector('.dc-hero-area-slider .slide');
        if (slideElement) {
            slideElement.style.backgroundImage = 'url(assets/images/banner/treetology-banner01.jpg)';
            slideElement.style.backgroundSize = 'cover';
            slideElement.style.backgroundPosition = 'center';
        }
    }, []);
    return (
        <>
            {/* <!-- start of dc-hero-area --> */}
            <section className="dc-hero-area dc-hero-area-style-1" id={id}>
                <div className="dc-hero-area-slider">
                    <div className="slide">
                        <div className="container">
                            <img src="assets/images/banner/treetology-banner01.jpg"  alt="banner" className="slider-bg" />
                            <div className="row">
                                <div className="col col-lg-8 col-md-9 slide-content heroContent textCenterMobileView">
                                    <div className="slide-top-title text-center topLineText">
                                        <div className="slide-top-title-inner"><i><img src="assets/images/icon/tea-leaf.png"
                                            alt="" /></i><span>YOUR TREE, YOUR IMPACT, A BETTER PLANET
                                                </span></div>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Empowering A Greener Tomorrow, Powered By Trees.</h2>
                                    </div>

                                    <div className="btns">
                                        <Link to="/#our-projects" className="ch-btn-style-1">Plant A Tree</Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </section>
            {/* <!-- end of dc-hero-area slider --> */}
        </>
    )
}

export default HeroSection