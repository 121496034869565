import { useEffect } from 'react'
import { fetchEndUserInfo } from './store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import Localstorage from './utils/storage/Localstorage';


// function ProtectedRoute({ children }) {
//     const dispatch = useDispatch()

//     const userInfo = useSelector((state) => state.userReducer.userInfo)
//     console.log('userInfo????',userInfo)
//     useEffect(() =>{
//         dispatch(fetchEndUserInfo())
//     },[dispatch])
//   return (
//     <div>ProtectedRoute</div>
//   )
// }
export default function ProtectedRoutes({ children }) {

    const navigate = useNavigate();
    const location = useLocation();
    const userDetails = useSelector((state) => state.userReducer?.userInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            const response = await dispatch(fetchEndUserInfo());
            return response;
        }

        const token = Localstorage.JWT_TOKEN.get();
        if (!token) {
            // return <Navigate to="/login" />;
            return navigate('/login',{ state: { from: location.pathname } });
        }

        if (!userDetails._id) {
            fetchData().then((response) => {
                if (response?.status !== 200) {
                    // return <Navigate to="/login" />;
                    return navigate('/login',{ state: { from: location.pathname } });
                }
            });
        }
    }, [userDetails?._id, dispatch,location.pathname,navigate]);

    return children;
}
