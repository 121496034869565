import React from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'

function SelectProject({ id,src, name, location, price  }) {
  return (
    // <div>SelectProject</div>
    <>
 <div className={styles.card}>
      <img
        src={src}
        alt={name}
        className={styles.cardImg}
      />
     <div className={styles.cardContentContainer}>
        <div className={styles.cardContent}>
          <h3 className={styles.cardTitle}>{name}</h3>
          <p className={styles.cardLocation}>{location}</p>
        </div>
        <Link to={`/ProjectDetails/${id}`} className={styles.arrowIconContainer}>
          <i className={`icon-arrow-right ${styles.arrowIcon}`}></i>
        </Link>
      </div>
    </div>
    </>
  )
}

export default SelectProject