import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Notification from '../notification/Notification';
import { saveReducerInfo, userLogin } from '../../store/userSlice';
import Localstorage from '../../utils/storage/Localstorage';
import { removeRedirectionPath } from '../../store/redirection.slice';

function LoginView() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  // const userInfo = useSelector((state) => state.userReducer.userInfo);

  const redirectionSelector = useSelector((state) => state.redirectionReducer.redirecPath);
  console.log("redirectionSelector", redirectionSelector)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      Notification({ message: 'Please fill all the fields', type: 'error' });
      // console.log("Please fill all the fields");
      return;
    }
    try {
      const response = await dispatch(userLogin({ email, password }));
      console.log('responseresponserespppp', response)
      if (response.status === 200) {
        Notification({ message: response.message, type: 'success' });
        await Localstorage.JWT_TOKEN.set(response.response.token);
        // await Localstorage.USER_TYPE.set(response.data.role);
        dispatch(saveReducerInfo(response.response.checkUser));

        // Handle redirection
        const redirectionPath = redirectionSelector || Localstorage.REDIRECTION_PATH.get();
        if (redirectionPath) {
          navigate(redirectionPath);
          // dispatch(removeRedirectionPath());
          // Localstorage.REDIRECTION_PATH.remove();
        } else {
          navigate('/');
        }

      } else {
        Notification({ message: response.message, type: 'error' });
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
      Notification({ message: 'An error occurred during login.', type: 'error' });
    }
  };

  const handleGoogleLogin = async () => {
    console.log("handleGoogleLogin***", process.env.REACT_APP_BACKEND_URL)
    window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback`, "_self")
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
        <div className="row w-100 d-flex justify-content-center">
          <div className="col-lg-8 col-md-10 col-12 d-flex justify-content-center">
            <div className="card w-100">
              <div className="row no-gutters">
                <div className="col-md-6 d-none d-md-block login-image" />
                <div className="col-md-6">
                  <div className="card-body p-4">
                    <div className="text-center mb-4">
                      <img src="/assets/images/treetology-logo.png" alt="Logo" className="logo" />
                    </div>
                    <h5 className="text-center mb-4 title-font">Login</h5>
                    <form onSubmit={login}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label" style={{ color: 'white' }}>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}

                        />
                      </div>
                      <div className="mb-3 position-relative">
                        <label htmlFor="password" className="form-label" style={{ color: 'white' }}>Password</label>
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          className="form-control"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                          className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} position-absolute pwdIcon`}
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <button
                          type="button"
                          className="btn btn-link frgtLink"
                        >Forgot Password?
                        </button>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary w-100 loginBtnStyle"
                      >
                        Login
                      </button>
                      <div className="text-center mt-3">
                        <p className="text-white">Don't have an account? <Link to="/registration" className="text-light">Register</Link></p>
                      </div>
                    </form>

                    <button
                      onClick={handleGoogleLogin}
                      className="btn btn-outline w-100 mt-3 border-1 border-white text-white"

                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {/* <Image src={googlelogo} width={30} height={30} />  */}
                      <img src="/assets/images/google.png" alt="google-logo" width={30} height={30} /> &nbsp;&nbsp;
                      <span>Google</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginView