// getAllProjects

import { createSlice } from "@reduxjs/toolkit";
import routes from '../utils/routes'
// import Notification from "../component/notification/Notification";

const initialState = {
    
    allProject: [],
    selectedProject:{},
    checkoutData: {
        qty: 1,
        plantPrice: 0,
        adoptPrice: 0,
        selectedOption: 'plant',
        name: ''
    }
}


export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        getAllProjects: (state,action) =>{
            state.allProject = action.payload
        },
        getSelectedProject: (state,action) =>{
            state.selectedProject = action.payload
        },
        setCheckoutData: (state, action) => {
            state.checkoutData = action.payload;
            // console.log("state.checkoutData",state.checkoutData)
        }
    }
})

export const { getAllProjects,getSelectedProject,setCheckoutData  } = projectSlice.actions;


export const fetchwebsiteProjects = () => async (dispatch) => {
    const response = await routes.APIS.getAllProjects();
    // console.log("fetchwebsiteProjects***", response)
    dispatch(getAllProjects(response.response.getAllProjects));
    return response;
}

export const fetchwebsiteProjectsByLink = (link) => async (dispatch) => {
    const response = await routes.APIS.getProjectByLink(link);
    // console.log("fetchwebsiteProjects***", response)
    dispatch(getSelectedProject(response.response));
    return response;
}


export default projectSlice.reducer;

