import React from 'react'
import styles from './styles.module.css'
import SelectProject from './SelectProjectCard/SelectProject';

function AdoptTree({id}) {
    const cardsData = [
        { id: 1,img:'/assets/images/gallery/proj1.jpeg', name: 'Bangalore, India', location: 'Urban Plantations', price: 300,shape: 'rounded' },
        { id: 2,img:'/assets/images/gallery/proj2.jpeg', name: 'Indonesia', location: 'Siberut Island', price: 499,shape: 'circular' },
        { id: 3,img:'/assets/images/gallery/proj3.jpeg', name: 'Papua New Guinea', location: 'Woodlark Island', price: 2884,shape: 'diamond' },
        { id: 4,img:'/assets/images/gallery/proj4.jpeg', name: 'Sunderbans, India', location: 'Tanzania', price: 950,shape: 'diamond' },
        { id: 5,img:'/assets/images/gallery/proj5.jpeg', name: 'Tanzania', location: 'Rufiji Delta', price: 240,shape: 'diamond' },
        { id: 5,img:'/assets/images/gallery/proj6.jpeg', name: 'Indian Cork', location: 'Millingtonia Hortensis', price: 240,shape: 'diamond' },
        { id: 5,img:'/assets/images/gallery/proj7.jpeg', name: 'Indian Cork', location: 'Millingtonia Hortensis', price: 240,shape: 'diamond' },
    ];
  return (
    <>
    <div className={`container-fluid ptb-120 ${styles.containerFluid}`} id={id}>
            {/* Title */}
            <h1 className={styles.title}>Select A Project</h1>
            {/* <h2 class="site-split-text ch-split-in-center"> Why Treetology ?</h2> */}
           
            {/* Buttons in a row */}
            {/* <div className={styles.buttonGroup}>
                <button className={styles.btn}>Button 1</button>
                <button className={styles.btn}>Button 2</button>
                <button className={styles.btn}>Button 3</button>
            </div> */}

            {/* Tree Cards Grid */}
            <div className={styles.cardGrid}>
                {cardsData.map(card => (
                    <SelectProject 
                        key={card.id}
                        id={card.id}
                        src={card.img}
                        name={card.name}
                        location={card.location}
                        price={card.price}
                        shape={card.shape}
                    />
                ))}
            </div>
        </div>
    </>
  )
}

export default AdoptTree