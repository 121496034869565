import React from 'react'
// import { GlobalLoaderContext } from '../Loader/GlobalLoader';
import LoginView from '../component/login/LoginView';
import Header from '../component/Header';
import Footer from '../component/Footer'


function LoginPage() {

  // const { showLoader, hideLoader } = useContext(GlobalLoaderContext);
  // useEffect(() => {
  //     showLoader();
  //     const timer = setTimeout(() => {
  //         hideLoader();
  //     }, 2000); // Simulate loading time or replace with actual data fetching

  //     return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <Header />
      <LoginView />
      <Footer />

    </>
  )
}

export default LoginPage