// useCurrencyConverter.js
import { useEffect, useState } from 'react';
import axios from 'axios';

const useCurrencyConverter = (selectedCurrency, selectedProject) => {
    const [exchangeRate, setExchangeRate] = useState(1);
    // const [convertedPlantPrice, setConvertedPlantPrice] = useState(0);
    // const [convertedAdoptPrice, setConvertedAdoptPrice] = useState(0);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            if (selectedCurrency !== 'INR') {
                try {
                    const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/INR`);
                    const rate = response.data.rates[selectedCurrency];
                    setExchangeRate(rate);
                } catch (error) {
                    console.error('Error fetching exchange rate:', error);
                }
            } else {
                setExchangeRate(1);
            }
        };

        fetchExchangeRate();
    }, [selectedCurrency]);

    // useEffect(() => {
    //     if (selectedProject) {
    //         setConvertedPlantPrice((selectedProject.plantPrice * exchangeRate).toFixed(2));
    //         setConvertedAdoptPrice((selectedProject.adoptPrice * exchangeRate).toFixed(2));
    //     }
    // }, [selectedProject, exchangeRate]);

    // useEffect(() => {
    //     if (selectedProject) {
    //         // Convert prices from INR to the selected currency
    //         const plantPriceInINR = selectedProject.plantPrice; // Assuming the price is in INR
    //         const adoptPriceInINR = selectedProject.adoptPrice; // Assuming the price is in INR

    //         setConvertedPlantPrice((plantPriceInINR * exchangeRate).toFixed(2));
    //         setConvertedAdoptPrice((adoptPriceInINR * exchangeRate).toFixed(2));
    //     }
    // }, [selectedProject, exchangeRate]);


    // return { convertedPlantPrice, convertedAdoptPrice };
    return { exchangeRate };
};

export default useCurrencyConverter;
