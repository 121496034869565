import styled from "styled-components";

export const CertificateContainer = styled.div`
  display: inline-block;
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;

  .main-container {
    width: 1755px;
    height: 1355px;
    position: relative;
    padding: 0;
  }

  .background-img {
    position: absolute;
    width: 1755px;
    height: 1355px;
    top: 0;
    left: 1px;
    object-fit: cover;
  }

  .right-side-image {
    position: absolute;
    width: 556px;
    height: 435px;
    top: 805px;
    left: 1199px;
    object-fit: cover;
  }

  .left-side-image {
    position: absolute;
    width: 718px;
    height: 401px;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  .fw-800 {
    font-weight: 800;
  }

  .content-footer-container {
    position: absolute;
    top: 145px;
    left: 106px;

    .content-container {
      width: 1544px;
      height: 950px;
      background-color: #2c6137;
      border-radius: 74px;
      position: relative;
      padding: 77px 82px 0px;
      align-items: center;
      display: flex;
      flex-direction: column;

      .logo-qr-container {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: end;
        height: 200px;

        .logo-image {
          position: absolute;
          width: 446px;
          height: 200px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      .cert-title {
        margin-top: 36px;
        font-weight: 100;
        color: #ffffff;
        font-size: 68px;
        letter-spacing: 0;
        line-height: normal;
      }

      .cert-title-gift{
        margin-top: 36px;
        font-weight: 700;
        color: #ffffff;
        font-size: 68px;
        letter-spacing: 0;
        line-height: normal;
      }

      .certificate-description {
        /* position: absolute; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .text {
          margin-bottom: -27px;
          font-size: 29px;
          position: relative;
          align-self: stretch;
          font-weight: 100;
          color: #ffffff;
          text-align: center;
          letter-spacing: 1px;
          line-height: normal;
        }

        &.above {
          margin-top: 36px;
          height: 77px;
          gap: 37px;
        }

        &.below {
          margin-top: 36px;
          gap: 30px;
          width: 1259px;
          height: 76px;

          .text {
            font-size: 25px;
            letter-spacing: 0px;
          }
        }
      }

      .purchase-detail-container {
        margin-top: 36px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 20px;

        .card-container {
          width: 335px;
          height: 71px;
          background-color: #ffffff;
          border-radius: 18px;
          display: flex;
          align-items: center;
          padding: 16px 22px;

          img {
            width: auto;
            height: 35px;
            object-fit: contain;
          }

          div {
            margin-left: 10px;
            font-weight: 800;
            color: #8f8f8f;
            font-size: 18px;
            letter-spacing: 0;
            line-height: normal;
            white-space: nowrap;
          }
        }
      }

      .signature-container {
        margin-top: 36px;

        .text-wrapper {
          font-weight: 400;
          color: #ffffff;
          font-size: 19px;
          text-align: center;
          letter-spacing: 0;
          line-height: normal;
          white-space: nowrap;
        }
      }
    }

    .footer {
      gap: 5px;
      height: 67px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 82px;
      padding-top: 32px;

      p {
        margin: 0;
        padding: 0;
        position: relative;
        align-self: stretch;
        color: #ffffff;
        font-size: 27px;
        letter-spacing: 0;
        line-height: normal;
      }

      .detail {
        font-weight: 200;
      }

      .link {
        font-weight: 800;
      }
    }
  }
`;
