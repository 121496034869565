import React from 'react'
import ServiceCard from './ServiceCard';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import styles from './styles.module.css'



function Services() {
  const services = [
    {
      imgSrc: 'assets/images/service/service-1.jpg',
      iconClass: 'icon-gardening-1',
      title: 'Create an Eco-Conscious Brand',
      duration: '1000ms',
      description: 'We plant trees with every product purchase and integrate tree monitoring through our APIs. Enhance your brands eco-conscious image and offer customers a tangible way to contribute to a greener planet.'
    },
    {
      imgSrc: 'assets/images/service/service-2.jpg',
      iconClass: 'icon-growth-1',
      title: 'Employee Gifting and Engagement Program',
      duration: '1200ms',
      description: 'Celebrate employees by planting trees on their behalf. Perfect for gifts and company milestones, this program fosters a positive environmental impact and strengthens employee engagement and satisfaction.'
    },
    {
      imgSrc: 'assets/images/service/service-3.jpg',
      iconClass: 'icon-gardening-tools-1',
      title: 'Engage in CSR Activities',
      duration: '1400ms',
      description: 'Dedicate spaces for tree planting and maintenance as part of your CSR initiatives. Enhance biodiversity, support local communities, and fulfill corporate social responsibility goals with impactful environmental projects.'
    },
    {
      imgSrc: 'assets/images/service/service-4.jpg',
      iconClass: 'icon-watering-can-1',
      title: 'Customer Loyalty Program',
      duration: '1600ms',
      description: 'Offset loyal customers emissions by planting trees on their behalf. Build strong customer loyalty with plantation certificates and eco-friendly campaigns that resonate with environmentally conscious consumers.'
    }
  ];
  return (
    <>
      {/* <!-- start of service-area --> */}

      <section className={`service-area pb-120 ${styles.serviceArea}`}>
        <div className="container">
          <div className="service-wrap">
            <div className="row">
              <div className="row justify-content-center">
                <div className="col col-lg-5 col-md-12 col-12">
                  <div className="ch-top-title-s2">
                    <span>Services</span>
                    <h2 className="site-split-text ch-split-in-up">Our Business Segment</h2>
                  </div>
                </div>
              </div>
              {services.map((service, index) => (
                <ServiceCard
                  key={index}
                  imgSrc={service.imgSrc}
                  iconClass={service.iconClass}
                  title={service.title}
                  duration={service.duration}
                  description={service.description}
                />
              ))}
              <div className="col-lg-12 text-center" style={{ paddingTop: '20px' }}>
                <Link smooth to="/#contact" className="ch-btn-style-1">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end of service-area  --> */}
    </>
  )
}

export default Services