import React from 'react';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';


const { Meta } = Card;

function BlogCard({ id, title, imgSrc, link }) {
    const navigate = useNavigate();

    const handleReadMore = () => {
        navigate(`/blog/${id}`);
    };

    return (
        <Card
            hoverable
            cover={<img alt={title} src={imgSrc} className="blog-card-image" style={{width:'100%', height:'200px', objectFit: 'cover'}} />}
            className="blog-card"
        >
            <Meta title={title} />
            <div className="btns pt-4 text-center">
                <Link to={`/blogdetails/${link}`} className="ch-btn-style-1">Read More</Link>
            </div>
        </Card>
    );
}

export default BlogCard;
