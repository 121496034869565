import React, { useEffect } from 'react'
import ProjectCard from './ProjectCard';
// import { Link } from 'react-router-dom';
// import { Carousel } from 'antd';
// import 'antd/dist/antd.css';
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { fetchwebsiteProjects } from '../store/projectSlice';


function OurProject({ id }) {

    const dispatch = useDispatch();
    const projectSelector = useSelector((state) => state.projectReducer.allProject)
    console.log("OurProjectOurProject", projectSelector)
    useEffect(() => {
        dispatch(fetchwebsiteProjects())
    }, [dispatch])

    // const projects = [
    //     { id: 1, imgSrc: '/assets/images/project/banglore.png', location: 'Bangalore, India', target: 10000, achieved: 2000, title: 'Urban Plantations', decription: "Bangalore breathes a little easier thanks to ICare Foundation and Treeology! Together, we've transformed urban pockets into havens for nature. Imagine streets lined with new life - native trees carefully chosen to suit the local ecology, providing a cool canopy and a vital habitat for birds and butterflies. Witnessing this transformation firsthand is a beautiful testament to the power of community and care. By joining Treeology's tree adoption program, you can be a part of this impactful initiative, creating a greener, healthier Bangalore, one tree at a time." },
    //     { id: 2, imgSrc: '/assets/images/project/indo.png', location: 'Indonesia', target: 40000, achieved: 10000, title: 'Siberut Island', decription: "The Siberut Island Forest Rehabilitation Project in Mentawai Island District, West Sumatra, Indonesia, aims to protect 19,875 hectares of native tropical forests from logging and industrial plantation. Running from 2016 to 2046, this project focuses on forest conservation, biodiversity protection, and community livelihood improvement. It is estimated to generate 9,941,071.96 tonnes of CO2 equivalent in certified emission reductions over 30 years. Key activities include establishing guard posts and patrols, enhancing natural regeneration, afforestation, and sustainable agroforestry. The project also provides jobs, training, and improves income and food security for local communities, contributing to several Sustainable Development Goals (SDGs). This region, covering an area of 3,838.25 km² with a population of 40,220 (2020 Census), hosts unique biodiversity with 65% of mammals and 15% of other animals being endemic." },
    //     { id: 3, imgSrc: '/assets/images/project/Guinea.png', location: 'Papua New Guinea', target: 30000, achieved: 500, title: 'Woodlark Island', decription: "Woodlark Island in Papua New Guinea, part of the Woodlark Islands archipelago, features unique geological and biodiversity aspects, including endemic species. The  initiative here aims to address climate change through sustainable forest management, reducing emissions from deforestation and forest degradation, and enhancing forest carbon stocks. The project promotes conservation, community empowerment, and climate action." },
    //     { id: 4, imgSrc: '/assets/images/project/Sunderbans.png', location: 'Sunderbans, India', target: 40000, achieved: 10000, title: 'Sunderbans Restoration', decription: "Mangrove restoration project in Tanga City is the project that aims at replanting mangroves in degraded mangrove areas in order to regain the original nature of the mangrove environment" },
    //     { id: 5, imgSrc: '/assets/images/project/Tanzania.png', location: 'Tanzania', target: 50000, achieved: 10000, title: 'Rufiji Delta', decription: "The project focuses on restoring mangrove ecosystems in the Indian Sundarbans, aiming to enhance climate change adaptation, biodiversity conservation, and GHG emission reductions. Project implementation involves collaboration with local communities. Initially, activities will cover 150 hectares, with an additional hectares identified for subsequent phases, totaling up to 23,11 hectares of potential plantation area. By adopting the sustainable conservation practices and actively involving the community, it seeks not only to protect the biodiversity but also to empower its people with opportunities for a resilient and sustainable future." },
    //     { id: 6, imgSrc: '/assets/images/project/banglore.png', location: 'Tamil Nadu, India', target: 10000, achieved: 2000, title: 'Urban Plantations', decription: "The project focuses on restoring mangrove ecosystems in the Indian Sundarbans, aiming to enhance climate change adaptation, biodiversity conservation, and GHG emission reductions. Project implementation involves collaboration with local communities. Initially, activities will cover 150 hectares, with an additional hectares identified for subsequent phases, totaling up to 23,11 hectares of potential plantation area. By adopting the sustainable conservation practices and actively involving the community, it seeks not only to protect the biodiversity but also to empower its people with opportunities for a resilient and sustainable future." },

    // ];

    // const carouselSettings = {
    //     // classNameName: 'testimonial-items testimonial-active testimonialCardMargin',project-active
    //     classNameName: 'project-active projectCardMargin',
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: false
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: false
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 initialSlide: 1,
    //                 dots: false

    //             }
    //         },
    //         {
    //             breakpoint: 575,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: false

    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: false

    //             }
    //         },
    //         {
    //             breakpoint: 426,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: false

    //             }
    //         }
    //     ]
    // };


    return (
        <>
        <section className={`ch-project-area pt-50 ${styles.projectGridMobile}`} id={id}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div className="ch-choose-text">
                            <div className="ch-top-title-s3">
                                <h2 className="site-split-text ch-split-in-right text-center" style={{ fontWeight: '700', paddingBottom: '2.3rem' }}>
                                    Our Projects
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${styles.gridContainer}`}>
                    {projectSelector?.map((project, index) => (
                        <div key={index} className={`col-md-6 col-lg-4 mb-4 ${styles.gridItem}`}>
                            <ProjectCard
                                id={project?._id}
                                imgSrc={`${process.env.REACT_APP_BACKEND_URL}/${project?.images?.[0] || ''}`}
                                location={project?.location}
                                title={project?.name}
                                description={project?.description}
                                plantPrice={project?.plantPrice}
                                adoptPrice={project?.adoptPrice}
                                link={project?.link}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>
    )
}

export default OurProject