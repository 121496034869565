import React, { useContext, useEffect } from 'react'
import Header from '../component/Header'
import HeroSection from '../component/plantTree/HeroSection'
import OurProject from '../component/plantTree/OurProject'
import Footer from '../component/Footer'
import About from '../component/plantTree/About'
import AdoptTree from '../component/plantTree/AdoptTree'
import { GlobalLoaderContext } from '../Loader/GlobalLoader'
import ClimateCrisis from '../component/plantTree/ClimateCrisis'

function PlantTree() {
    const { showLoader, hideLoader } = useContext(GlobalLoaderContext);
    useEffect(() => {
        showLoader();
        const timer = setTimeout(() => {
            hideLoader();
        }, 2000);

        return () => clearTimeout(timer);
    }, [showLoader, hideLoader]);

    return (
        <>

            <div class="page-wrap">
                {/* <div class="preloader">
                    <div class="preloader__image" style={{ backgroundImage: 'url(assets/images/treetology-logo.png)' }}></div>
                </div> */}
                <Header />
                <HeroSection id="hero-section" />
                <AdoptTree />
                <About id="about" />
                <ClimateCrisis />
                <OurProject id="our-projects" />
                <Footer id="contact" />
            </div>
        </>
    )
}

export default PlantTree