import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import ProjectOrderWithGiftDataTable from '../component/myActivity/ProjectOrderWithGiftDataTable'

function MyGifts() {
  return (
   <>
            <Header />
            <section className='ptb-120'>
                <div className='container '>
                    <h1>My Gifts</h1>
                    <ProjectOrderWithGiftDataTable />
                </div>

            </section>
            <Footer />
   </>
  )
}

export default MyGifts