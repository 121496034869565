// CurrencySelector.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCurrency } from '../store/currencySlice';
import styles from './styles.module.css'

const CurrencySelector = () => {
    const dispatch = useDispatch();
    const currency = useSelector((state) => state.currencyReducer.selectedCurrency); // Access the selected currency from the store

    const handleCurrencyChange = (event) => {
        const selectedCurrency = event.target.value;
        dispatch(setSelectedCurrency(selectedCurrency)); // Dispatch the action to set the currency
    };

    return (
        <div className="col-lg-10">
            {/* <label>Select Currency:</label> */}
            <select value={currency} onChange={handleCurrencyChange}
                className={`form-select ${styles.customSelect}`}
                style={{ alignItems: 'right', padding: '10px 30px 10px 10px', borderRadius: '25px', backgroundColor: '#ffffff00', marginRight: '50px', color: '' }}>
                <option value="INR">INR - Indian Rupee</option>
                <option value="USD">USD - US Dollar</option>
                <option value="EUR">EUR - Euro</option>
                <option value="GBP">GBP - British Pound</option>
                <option value="AUD">AUD - Australian Dollar</option>
                <option value="CAD">CAD - Canadian Dollar</option>
                <option value="JPY">JPY - Japanese Yen</option>
                <option value="CHF">CHF - Swiss Franc</option>
                <option value="SGD">SGD - Singapore Dollar</option>
                <option value="NZD">NZD - New Zealand Dollar</option>
                <option value="AED">AED - UAE Dirham</option>
                <option value="BRL">BRL - Brazilian Real</option>
                <option value="HKD">HKD - Hong Kong Dollar</option>
                <option value="KRW">KRW - South Korean Won</option>
                <option value="MXN">MXN - Mexican Peso</option>
                <option value="MYR">MYR - Malaysian Ringgit</option>
                <option value="PHP">PHP - Philippine Peso</option>
                <option value="RUB">RUB - Russian Ruble</option>
                <option value="SEK">SEK - Swedish Krona</option>
                <option value="THB">THB - Thai Baht</option>
                <option value="ZAR">ZAR - South African Rand</option>
            </select>
        </div>
    );
};

export default CurrencySelector;
