import React from 'react'
import MyActivity from '../component/myActivity/MyActivity'
import Header from '../component/Header'
import Footer from '../component/Footer'

function UserMyActivity() {
  return (
    <>
     <div class="page-wrap">
                {/* <div class="preloader">
                    <div class="preloader__image" style={{ backgroundImage: 'url(assets/images/treetology-logo.png)' }}></div>
                </div> */}
                <Header />

            </div>
                <MyActivity />
            <Footer id="contact" />
    </>
  )
}

export default UserMyActivity