import React from 'react'
import ProjectOrderDataTable from '../component/myActivity/ProjectOrderDataTable'
import Header from '../component/Header'
import Footer from '../component/Footer'

function MyOrder() {
    return (
        <>
            <Header />
            <section className='ptb-120'>
                <div className='container '>
                    <h1>My Orders</h1>
                    <ProjectOrderDataTable />
                </div>

            </section>
            <Footer />
        </>
    )
}

export default MyOrder