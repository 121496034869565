import React from 'react'
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';


function Banner() {
    return (
        <>
            {/* <section class="work-area ptb-120 pt-0 desktop-banner">
                <img src="assets/images/banner/Treetology_Banner1_new.jpg" alt='banner2' width="100%" />
            </section>
            <section class="work-area ptb-120 pt-0 mobile-banner">
                <img src="assets/images/banner/mob-banner.png" alt='banner2' width="100%" />
            </section>
            <button><Link to="/plantTree" className="ch-btn-style-2 d-none d-lg-block plantBtnpadding">Plant With Us Today</Link></button> */}
            <div className="banner-container">
            <section className="work-area ptb-120 pt-0 desktop-banner">
                <div className="image-container">
                    <img src="assets/images/banner/Treetology_Banner1_Changes.jpg" alt='banner2' width="100%" />
                    <button className="banner-button">
                        <Link smooth to="/#our-projects" className="ch-btn-style-2">Plant With Us Today</Link>
                    </button>
                </div>
            </section>
            <section className="work-area ptb-120 pt-0 mobile-banner">
                <div className="image-container">
                    <img src="assets/images/banner/Mobile View Banner Our Process_changes.jpg" alt='banner2' width="100%" />
                    <button className="banner-button">
                        <Link smooth to="/#our-projects" className="ch-btn-style-2">Plant With Us Today</Link>
                    </button>
                </div>
            </section>
        </div>
            {/* <li style={{marginRight:'1rem'}}><Link to="/plantTree" className="ch-btn-style-2 d-none d-lg-block plantBtnpadding">Plant With Us Today</Link></li> */}

        </>
    )
}

export default Banner