import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const RefundPolycy = () => {
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <h1 className="mb-4">Return and Refund Policy</h1>

                    <section className="mb-4">
                        <p>
                            At Treetology, we value the trust you place in us and are committed to making a positive environmental impact with every purchase. We aim to ensure that your experience with our services and products is seamless and satisfying. However, we understand that there may be instances where you need to request a return or refund. Please carefully review our return policy outlined below.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>1. Non-Refundable Services</h2>
                        <p>
                            •	Due to the nature of our services, which involve the direct planting of trees, adoption of trees, or gifting tree certificates, the following applies:
                        </p>
                        <p>
                            •	Tree Planting Services: Once the order for planting a tree has been processed and the tree is planted, this action is irreversible. Therefore, payments made toward tree planting are non-refundable.
                        </p>
                        <p>
                            •	Tree Adoption Services: Payments made for adopting a tree, including those for the ongoing care and maintenance of the tree, are non-refundable once the tree adoption is confirmed. The adoption helps ensure the tree's growth and its positive environmental impact, which cannot be undone.
                        </p>
                        <p>
                            •	Tree Gifting Services: Once a tree gift order has been processed and the recipient has been notified, the action is considered complete, and the payment is non-refundable.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>2. Order Cancellations</h2>
                        <p>
                            •	Tree Planting and Adoption Orders: You may request to cancel your order for tree planting or tree adoption within 24 hours of placing the order. After this 24-hour window, cancellations are no longer possible as we begin the process of planting or adopting the tree. Cancellations beyond this period cannot be honored due to the operational efforts involved in initiating the environmental activities.
                        </p>
                        <p>
                            •	Tree Gifting Orders: Tree gifting orders can also be canceled within 24 hours of placing the order, provided the recipient has not yet been notified. If the notification has been sent or the 24-hour window has passed, the gift cannot be canceled.
                        </p>
                        <p>
                            •	To request a cancellation, please contact us at
                            <a href="mailto:operations@sustainology.life"> operations@sustainology.life</a> with your order details, and our team will assist you.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>3. Return of Physical Products</h2>
                        <p>
                            •	If you have purchased any physical products (e.g., eco-friendly merchandise) from Treetology, the following return policy applies:
                        </p>
                        <p>
                            •	Eligibility for Return: You may request a return or exchange of physical products within 14 days of receiving the product. To be eligible, the item must be in its original condition, unused, and in the same packaging in which it was delivered. Any items that have been used, damaged, or altered in any way are not eligible for return.
                        </p>
                        <p>
                            •	Return Process: To initiate a return, please email us at operations@sustainology.life  with your order number, the product you wish to return, and the reason for return. We will provide instructions on how to return the item.
                        </p>
                        <p>
                            •	Return Shipping Costs: The customer is responsible for paying the shipping costs for returning the item unless the product is defective or damaged upon arrival. We recommend using a trackable shipping service or purchasing shipping insurance, as we cannot guarantee that we will receive the returned item.
                        </p>
                        <p>
                            •	Return Approval and Inspection: Once we receive your returned item, it will be inspected to ensure it meets the return eligibility criteria. We reserve the sole discretion to approve or reject any refund or return request based on our assessment of the product's condition and compliance with our return policy. You will be notified of the status of your return after the inspection is complete.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h2>4. Refund Policy</h2>
                        <p>
                            •	If your return of a physical product is approved, the following applies:
                        </p>
                        <p>
                            •	Refund Process: Once the return is approved, we will process your refund within 7-10 business days. The refund will be credited to your original payment method, such as your credit card or PayPal account.
                        </p>
                        <p>
                            •	Non-Refundable Costs: Please note that any original shipping fees associated with the order are non-refundable. Additionally, we do not offer refunds for services related to tree planting, adoption, or gifting, as outlined in the Non-Refundable Services section.
                        </p>
                        <p>
                            •	Delayed or Missing Refunds: If you haven’t received a refund after the designated processing period, please check with your bank or credit card provider first, as it may take additional time for the refund to be posted. If the issue persists, contact us at <a href="mailto:operations@sustainology.life">  operations@sustainology.life</a>  for further assistance.
                        </p>
                    </section>

                    <section>
                        <h2>5. Exchange Policy</h2>
                        <p>
                            •	We are happy to offer exchanges under the following conditions:
                        </p>
                        <p>
                            •	Defective or Damaged Products: If your physical product arrives damaged or defective, you may request an exchange within 14 days of receiving the product. Please email us at <a href="mailto:operations@sustainology.life">  operations@sustainology.life</a>  with details and photos of the defect or damage, and we will provide further instructions.
                        </p>
                        <p>
                            •	Exchange Process: Once the defective or damaged product is received, we will inspect the item and, if the claim is approved, we will promptly send a replacement product. We reserve the right to approve or reject exchange requests based on the condition of the returned product.
                        </p>

                    </section>

                    <section>
                        <h2>6. Gift Cards and Digital Products</h2>
                        <p>
                            •	Gift Cards: Purchases of gift cards from Treetology are non-refundable. Once a gift card is issued, it cannot be returned, canceled, or exchanged for cash or other services.
                        </p>
                        <p>
                            •	Digital Certificates: Any digital tree certificates, including those gifted to loved ones or as part of tree adoption services, are considered final once delivered. These digital products are non-refundable.
                        </p>
                    </section>

                    {/* Add additional sections from 7 to 20 similarly */}

                    <section>
                        <h2>7. Modifications to Orders</h2>
                        <p>
                            •	Changes to Tree Planting or Adoption Details: If you need to modify details such as the recipient's name or message for tree gifting or adoption services, please contact us within 24 hours of placing the order. We will accommodate reasonable modifications where possible, provided the tree has not yet been planted or the adoption process has not started.
                        </p>
                        <p>
                            •	Modification of Physical Product Orders: If you need to change the quantity or type of physical product ordered, please reach out to us at operations@sustainology.life    within 24 hours of placing your order. Once the order is shipped, modifications may not be possible.
                        </p>

                    </section>

                    <section>
                        <h2>8. Contact Us</h2>
                        <p>
                            •	For any questions regarding our return and refund policy, or to request a return, cancellation, or exchange, please contact our support team at:
                        </p>
                        <p>
                            •	Email: <a href="mailto:operations@sustainology.life"> operations@sustainology.life</a>
                        </p>

                    </section>
                </Col>
            </Row>
        </Container>
    );
};

export default RefundPolycy;
