// PaymentCancelled.js
import React from 'react';
import { Button } from 'antd'; // Assuming you're using Ant Design
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Localstorage from '../utils/storage/Localstorage';

const PaymentCancelled = () => {
    const navigate = useNavigate();

    const redirectionSelector = useSelector(
        (state) => state.redirectionReducer.redirecPath
    );
    const redirectionPath = redirectionSelector || Localstorage.REDIRECTION_PATH.get();

    const handleBack = () => {
        if (redirectionPath) {
            navigate(redirectionPath);
        } else {
            navigate('/');
        }
    };

    return (
        <div>
            <h1>Payment Cancelled!</h1>
            <p>The transaction was cancelled. Please try again.</p>
            <div>
                <Button type="primary" onClick={handleBack}>
                    Back
                </Button>
            </div>
        </div>
    );
};

export default PaymentCancelled;
