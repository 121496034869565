import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Carousel } from 'antd';
import BlogCard from './blog/BlogCard';
import { fetchwebsiteBlogs } from '../store/blogsSlice';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function HomeBlogSection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const blogSelector = useSelector((state) => state.blogsReducer.allBlogs);
    const loading = useSelector((state) => state.blogsReducer.loading);
    const error = useSelector((state) => state.blogsReducer.error);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        dispatch(fetchwebsiteBlogs());

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);

    const handleExploreMore = () => {
        navigate('/blogs'); // Redirect to blog page
    };

    const PrevArrow = ({ onClick }) => (
        <div className={`${styles.arrow} ${styles.prev}`} onClick={onClick}>
            <LeftOutlined />
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className={`${styles.arrow} ${styles.next}`} onClick={onClick}>
            <RightOutlined />
        </div>
    );

    return (
        <div className={`container ptb-60 ${styles.homeBlogSection}`}>
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="ch-top-title-s2">
                        <span>Our Blogs</span>
                        <h2 className="site-split-text ch-split-in-up">Our Latest Blogs</h2>
                    </div>
                </div>
            </div>
            {loading ? (
                <p>Loading blogs...</p>
            ) : error ? (
                <p>Error loading blogs: {error}</p>
            ) : (
                <>
                    {isMobile ? (
                        <Carousel
                            autoplay
                            arrows
                            prevArrow={<PrevArrow />}
                            nextArrow={<NextArrow />}
                        >
                            {blogSelector.slice(0, 4).map((blog) => (
                                <div key={blog._id} style={{ padding: '0 10px' }}>
                                    <BlogCard
                                        imgSrc={`${process.env.REACT_APP_BACKEND_URL}/${blog.image || ''}`}
                                        title={
                                            blog.title.length > 20
                                                ? `${blog.title.slice(0, 20)}...`
                                                : blog.title
                                        }
                                        link={blog.link}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    ) : (
                        <Row
                            gutter={[16, 16]}
                            justify="space-between"
                            align="middle"
                            style={{ margin: '0 auto', maxWidth: '1200px' }}
                        >
                            {blogSelector.slice(0, 4).map((blog) => (
                                <Col key={blog._id} xs={24} sm={12} md={8} lg={6}>
                                    <BlogCard
                                        imgSrc={`${process.env.REACT_APP_BACKEND_URL}/${blog.image || ''}`}
                                        title={
                                            blog.title.length > 20
                                                ? `${blog.title.slice(0, 20)}...`
                                                : blog.title
                                        }
                                        link={blog.link}
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}
                    <div className="col-lg-12 text-center" style={{ paddingTop: '20px' }}>
                        <Link smooth to="/blogs" className="ch-btn-style-1">
                            Explore More
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
}

export default HomeBlogSection;
