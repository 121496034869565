import React from 'react'
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import styles from './styles.module.css'



function ServiceCard({ imgSrc, iconClass, title, duration, description }) {
    return (
        <div className={`col col-lg-3 col-md-6 col-12 ${styles.serviceContainer}`}>
            <div className="flip-container" ontouchstart="this.classList.toggle('hover');">
                <div className="flipper">
                    <div className="front">
                        <div className="service-item fadeInUp" data-wow-duration={duration}>
                            <div className="service-img">
                                <img src={imgSrc} alt={title} />
                            </div>
                            <div className="service-text">
                                <div className="icon">
                                    <i className={iconClass}></i>
                                </div>
                                <h3><Link to="">{title}</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="back backTxtStyle">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard