import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import styles from './styles.module.css'



function ProjectCard({ imgSrc, location, title }) {
  return (
    <div className="project-item wow fadeInUp" data-wow-duration="1000ms">
      <div className="project-item-inner">
        <div className={`project-img middle-shade ${styles.projectImg}`}>
          <img src={imgSrc} alt={location} />
        </div>
        {/* <div className="project-text">
          <span>{location}</span>
          <h2>{title}</h2>
          <Link to="#" data-bs-toggle="modal" data-bs-target="#myModal">
            <i className="icon-arrow-right"></i>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default ProjectCard;
