import React, { useContext, useEffect } from 'react'
import { GlobalLoaderContext } from '../Loader/GlobalLoader'
import Header from '../component/Header';
import HeroSection from '../component/HeroSection'
import About from '../component/About'
// import OurProject from '../component/OurProject'
import Banner from '../component/Banner';
import Pricing from '../component/Pricing';
import Services from '../component/Services';
import Testimonial from '../component/Testimonial'
import Brand from '../component/Brand'
import Footer from '../component/Footer'
import Counter from '../component/Counter'
// import AdoptTree from '../component/plantTree/AdoptTree';
import ClimateCrisis from '../component/plantTree/ClimateCrisis';
import PlantTreeAbout from '../component/plantTree/About'
import OurProjectGrid from '../component/OurProjectGrid'
import HomeBlogSection from '../component/HomeBlogSection';



function Home() {
    const { showLoader, hideLoader } = useContext(GlobalLoaderContext);
    useEffect(() => {
        showLoader();
        const timer = setTimeout(() => {
            hideLoader();
        }, 2000);

        return () => clearTimeout(timer);
    }, [showLoader, hideLoader]);

    return (
        <>
            <div class="page-wrap">
                <Header />
                <HeroSection id='home' />
                <Counter />
                {/* <OurProject id="our-projects" /> */}
                <OurProjectGrid id="our-projects" />

                {/* <AdoptTree id="our-projects" /> */}
                <About id="about" />
                <Banner />
                <Pricing id="howItWorks" />
                <Services />
                <ClimateCrisis />
                <PlantTreeAbout />

                <HomeBlogSection />
                <Testimonial id='testimonials' />
                <Brand />
                <Footer id="contact" />
            </div>
        </>
    )
}

export default Home