import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import 'animate.css';

function ProjectCard({ id, imgSrc, location, title, link }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`project-item ${inView ? 'animate__animated animate__fadeInUp' : ''}`}
      data-wow-duration="1000ms"
    >
      <div className="project-item-inner">
        <div className="project-img middle-shade">
          <img src={imgSrc} alt={location} style={{height: '300px', width:'100%',}} />
        </div>
        <div className="project-text">
          <span>{location}</span>
          <h2>{title}</h2>
          <Link to={`/ProjectDetails/${link}`}>
            <i className="icon-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
