import React, { useContext, useEffect } from 'react'
import { GlobalLoaderContext } from '../Loader/GlobalLoader';
import Header from '../component/Header';
import Footer from '../component/Footer';
import BlogDetailsPage from '../component/blog/BlogDetailsPage';
function BlogDetails() {
    const { showLoader, hideLoader } = useContext(GlobalLoaderContext);
    useEffect(() => {
        showLoader();
        const timer = setTimeout(() => {
            hideLoader();
        }, 2000);

        return () => clearTimeout(timer);
    }, [showLoader, hideLoader]);

    return (
        <>
     
            <div class="page-wrap">
                <Header />
                <BlogDetailsPage />
            </div>
            <Footer id="contact" />
        </>
    );
}

export default BlogDetails