
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    redirecPath: null
}

export const redirectionSlice = createSlice({
    name: 'redirection',
    initialState,
    reducers: {
        setRedirectionPath: (state, action) => {
            state.redirecPath = action.payload
        },
        removeRedirectionPath: (state, action) => {
            state.redirecPath = null
        }
    }
})
export const { setRedirectionPath, removeRedirectionPath } = redirectionSlice.actions;


export default redirectionSlice.reducer;
