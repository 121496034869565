import React, { useState } from 'react'
// import { useDispatch } from 'react-redux';
// import { userSignUp } from '../../store/userSlice';

function Registration({ closeModal, onRegistrationSuccess,setIsModalVisible }) {

    // const dispatch = useDispatch();
    // const registerUser = useSelector((state) => state.user.userRegistration)
    // console.log('registerUser',registerUser)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobileNumber: '',
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.mobileNumber) formErrors.mobileNumber = 'Mobile Number is required';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate(formData)) {
            return;
        }
        // await dispatch(userSignUp(formData));
        // setFormData({
        //     name: '',
        //     email: '',
        //     mobileNumber: '',
        // })
        onRegistrationSuccess(formData);
        setIsModalVisible(false)
        setFormData({
            name: '',
            email: '',
            mobileNumber: '',
          });
    };
    // console.log('formData', formData)

    return (
        <>
            <div
                className="container-fluid d-flex justify-content-center align-items-center"
                style={{ backgroundImage: 'url(/assets/images/banner/banner-alt.jpg)', filter: 'drop-shadow(2px 4px 6px black)' }}
            >
                <div className="row w-100 d-flex justify-content-center">
                    <div className="col-lg-12 model-cls" style={{ paddingTop: '10px' }}>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center">
                        <div className="card w-100">
                            <div className="row no-gutters ">
                                <div className="col-md-12">
                                    <div className="card-body p-4">
                                        <h5 className="text-center mb-4 title-font">Register</h5>
                                        <p>Register your account with given below the details</p>
                                        <form onSubmit={handleSubmit} >
                                            <div className="mb-3">
                                                <label htmlFor="username" className="form-label" style={{ color: 'white' }}>Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name='name'
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label" style={{ color: 'white' }}>Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="mobileNumber" className="form-label" style={{ color: 'white' }}>Message</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="mobileNumber"
                                                    name='mobileNumber'
                                                    value={formData.mobileNumber}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {errors.mobileNumber && <div className="text-danger">{errors.mobileNumber}</div>}
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-100 loginBtnStyle"
                                            >
                                                Register
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Registration