import React from 'react';
import styles from './styles.module.css'


function BrandItem({ imgSrc, altText }) {
  return (
    <div className={`brand-item ${styles.brandItem}`}>
      <i><img src={imgSrc} alt={altText} /></i>
    </div>
  );
}

export default BrandItem;
