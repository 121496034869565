import React from 'react'
import Header from '../component/Header';
import Footer from '../component/Footer'
import TermsConditions from '../component/terms/TermsConditions';


const Terms = () => {
    return (
        <>
            <Header />
            <TermsConditions />
            <Footer />
        </>
    )
}

export default Terms

